/* eslint-disable brace-style */
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'
import jwt from 'jsonwebtoken'

import viewPatient from '../view/Patient'
import viewConfirm from '../view/Confirm'
import viewCheckIn from '../view/CheckIn'
import viewNoCustomerFound from '../view/NoCustomerFound'
import success from '../view/success'

import findCustomer from '../assets/fakeServer'

Vue.use(Router)

const router = new Router({
  mode: 'history', // Demo is living in GitHub.io, so required! hash history
  history: true,
  linkActiveClass: 'is-active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [
    {
      path: '/',
      name: 'patient',
      component: viewPatient,
      meta: {
        auth: false
      }
    },
    {
      path: '/confirm',
      name: 'Confirmar agendamento',
      component: viewConfirm,
      meta: {
        auth: false
      }
    },
    {
      path: '/check-in',
      name: 'checkIn',
      component: viewCheckIn,
      meta: {
        auth: false
      }
    },
    {
      path: '/404',
      name: 'no customer',
      component: viewNoCustomerFound
    },
    {
      path: '/success',
      name: 'success',
      component: success
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.query.token && to.query.token !== '') {
    console.log('Token found on query.')
    store.dispatch('patient/setToken', to.query.token)
    const customer = jwt.decode(to.query.token)
    const customerData = findCustomer(customer.customer)
    store.dispatch('patient/setCustomer', customerData)
  }
  else if (to.query.from && to.query.from !== '') {
    console.log('Customer code found on query.')
    const customerData = findCustomer(to.query.from)
    store.dispatch('patient/setCustomer', customerData)
  }
  else if (JSON.parse(localStorage.getItem('customer')) && JSON.parse(localStorage.getItem('customer')).code && JSON.parse(localStorage.getItem('customer')).code !== '') {
    console.log('Customer found on LocalStorage.')
    const customerData = findCustomer(JSON.parse(localStorage.getItem('customer')).code)
    store.dispatch('patient/setCustomer', customerData)
  }
  else if (to.path !== '/404') {
    console.log('Customer code not found.')
    return router.push('/404')
  }
  store.dispatch('auth/loginSpitzer').then(() => {
    store.dispatch('patient/getHealthInsurance')
      .then((obj) => {
        store.dispatch('patient/alert', obj)
      })
  })

  return next()
})

export default router

// window.axios.defaults.headers.common['x-access-token'] = store.state.auth.token
// // Add a request interceptor
// axios.interceptors.request.use(function (config) {
//   const token = store.getState().session.token;
//   config.headers.Authorization = token;

//   return config;
// });
