/* =========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */

import router from '../../../route'
import axiosAPI from '../../../axios-API'
import store from '../../store'

export default {
  confirm ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload, 'payload confirm')
      var config = {
        headers: {
          authorization: `Bearer ${store.state.auth.token}`,
          'x-access-token': store.state.auth.token
        },
        params: {
          id: payload,
          customer: process.env.VUE_APP_CUSTOMER
        }
      }
      axiosAPI.post('/web-check-in/schedule/confirm', null, config).then((response) => {
        return resolve(Math.floor(response.status / 100) === 2)
      }).catch(err => reject(err))
    })
  },
  cancel ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      console.log(payload, 'payload cancel')
      var config = {
        headers: {
          authorization: `Bearer ${store.state.auth.token}`,
          'x-access-token': store.state.auth.token
        },
        params: {
          id: payload,
          customer: process.env.VUE_APP_CUSTOMER
        }
      }
      axiosAPI.post('/web-check-in/schedule/cancel', null, config).then((response) => {
        return resolve(Math.floor(response.status / 100) === 2)
      }).catch(err => reject(err))
    })
  },
  // search Schedule
  setSearchPatientSchedule ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      var config = {
        headers: {
          authorization: `Bearer ${store.state.auth.token}`,
          'x-access-token': store.state.auth.token
        },
        params: {
          patient: payload,
          customer: process.env.VUE_APP_CUSTOMER
        }
      }
      axiosAPI.get('/web-check-in/patient/find-schedule', config).then((response) => {
        router.push(`/patient?token=${response.data.results}`)
        return resolve(commit('SET_HEALTH_INSURANCE', payload))
      }).catch(err => reject(err))
    })
  },
  setSearchPatientSchedulebyId ({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      var config = {
        headers: {
          authorization: `Bearer ${store.state.auth.token}`,
          'x-access-token': store.state.auth.token
        },
        params: {
          idExternal: payload,
          from: rootState.patient.customer.code
        }
      }
      axiosAPI.get('/web-check-in/schedule/find-schedule', config).then((response) => {
        const data = response.data.results[0]
        const patient = {
          idSchedule: data.id || '',
          record: data.record || '',
          hour: data.hour || '',
          date: data.date || '',
          doctorCode: data.doctorCode || '',
          doctorTitle: data.doctorTitle || '',
          path: data.path || '',
          tbPathImgDoctor: data.tbPathImgDoctor || '',
          typeSCheduleCode: data.typeSCheduleCode || '',
          customer: '',
          code: data.code || '',
          name: data.name || '',
          birthDay: data.birthDay || data.birthDay || '',
          gender: data.gender || '',
          humanRaceCode: `${data.humanRaceCode}` || '',
          maritalStatus: `${data.maritalStatus}` || '',
          origin: data.origin || '',
          naturalness: data.naturalness || '',
          nationality: data.nationality || '',
          rg: data.rg || '',
          cpf: data.cpf || data.cpf || data.tbCPF || '',
          cns: data.cns || '',
          zipCode: data.zipCode || '',
          address: data.address || data.address || '',
          number: data.number || '',
          complement: data.complement || '',
          neighborhood: data.neighborhood || '',
          city: data.city || data.city || '',
          data: data.data || '',
          healthInsuranceCode: data.healthInsuranceCode || '',
          registration: data.registration || '',
          healthInsurancePlan: data.healthInsurancePlan || '',
          cardValidity: '',
          homePhone: data.homePhone || '',
          officePhone: data.officePhone || '',
          mobile: data.mobile || '',
          email: data.email || data.email || ''
        }
        commit('patient/ASSIGN_FORM_DATA', patient, { root: true })
        return ({
          text: 'Dados do paciente carregados com sucesso',
          color: 'success'
        })
      }).catch(err => reject(err))
    })
  }
}
