/* =========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */

export default {
  isLogged: false,
  token: null
}
