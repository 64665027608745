<template>
  <v-row justify="center">
    <v-col
      cols="11"
      md="8"
    >
      <v-card
        :loading="loading"
        class="mx-auto my-12"
        max-width="374"
      >
        <v-img
          height="250"
          contain
          :src="getCustomerLogo"
        >
          <template v-slot:placeholder>
            <v-card
              color="rgba(0, 0, 0, 0.05)"
              width="100%"
              height="100%"
            >
              <v-row
                class="fill-height ma-0 pa-4"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
                {{getCustomerName}}
              </v-row>
            </v-card>
          </template>
        </v-img>

        <div>

          <v-card-title>Agendamentos Encontrados</v-card-title>

          <v-card-text>
            <div class="my-4 subtitle-1">
              {{dateNow}}
            </div>

            <div>Segue os dados da sua(s) consulta(s).</div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <div v-for="(item, index) in patientSchedules" :key="index">
            <h5 class="ml-5 pa-0">Profissional</h5>
            <v-card-title
              class="d-flex justify-space-between"
            >
              Dr(a) {{item.doctorTitle}}
            </v-card-title>
            <v-card-subtitle>
              <v-chip class="deep-purple accent-4 white--text">{{getDate(item.date)}}</v-chip>
              <v-chip class="deep-purple accent-4 white--text ml-1">{{getTime(item.hour)}}</v-chip>
            </v-card-subtitle>
            <v-divider class="mx-4"></v-divider>
          </div>
            <v-row justify="end">
              <v-btn
                class="ma-3 mr-6"
                color="primary"
                @click="checkIn(patientSchedules[0])"
              >
                Check-In
              </v-btn>
            </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    loading: false,
    selection: 0,
    dateNow: moment().format('DD/MM/YYYY')
  }),
  mounted () {
    this.$loading(false)
  },
  computed: {
    ...mapGetters('patient', ['patientSchedules', 'getCustomerLogo', 'getCustomerName'])
  },
  methods: {
    ...mapActions('patient', ['setCheckInScheduleData']),
    reserve () {
      this.loading = true
      setTimeout(() => (this.loading = false), 2000)
    },
    getDate (value) {
      return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
      // const clear = value.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})T/)
      // return (clear[3] + '/' + clear[2] + '/' + clear[1])
    },
    getTime (value) {
      return moment(value, 'YYYY-MM-DD HH:mm').format('HH:mm')
      // const clear = value.match(/T([0-9]{2}):([0-9]{2})/)
      // return (clear[1] + ':' + clear[2])
    },
    checkIn (item) {
      this.setCheckInScheduleData(item)
      this.$router.push('/check-in')
    }
  }
}
</script>
