/* =========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */

export default {
  // //////////////////////////////////////
  // App data
  loading: true,

  // //////////////////////////////////////
  // Session info
  isLogged: false,
  customer: {
    name: null,
    _id: null,
    code: null,
    logo: null
  },

  // //////////////////////////////////////
  // Token data
  tokenValid: false,
  token: null,
  decoded: {},

  // //////////////////////////////////////
  // Patient form controls
  formOpen: false,

  // //////////////////////////////////////
  // Patient form data
  patient: {
    idSchedule: '',
    record: '',
    hour: '',
    date: '',
    doctorCode: '',
    doctorTitle: '',
    path: '',
    tbPathImgDoctor: '',
    typeSCheduleCode: '',
    customer: '',
    code: '',
    name: '',
    birthDay: '',
    gender: '',
    humanRaceCode: '',
    maritalStatus: '',
    origin: '',
    naturalness: '',
    nationality: '',
    rg: '',
    cpf: '',
    cns: '',
    zipCode: '',
    address: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    healthInsuranceCode: '',
    registration: '',
    healthInsurancePlan: '',
    cardValidity: '',
    homePhone: '',
    officePhone: '',
    mobile: '',
    email: ''
  },

  // //////////////////////////////////////
  // Patient schedules
  healthInsurance: [],

  // //////////////////////////////////////
  // Patient schedules
  patientSchedules: [],
  checkInSchedule: {},

  // //////////////////////////////////////
  // ALERT
  alert: {
    snackbar: false,
    color: 'error',
    mode: '',
    timeout: 3000,
    text: 'Erro de comunicação com o servidor, por favor verifique sua conexão e tente novamente.'
  }
}
