<template>
  <v-container>
      <div class="text-center">
        <!-- <span><qrcode :value="`https://busca.clinic.inf.br/completed?code=${patient}`" :options="{ width: 200 }"></qrcode></span><br> -->
        <v-form @keyup.enter.native="search()" action="#" ref="form" @submit.prevent="search()">
          <v-flex>
            <v-img
              src="../assets/logo.png"
              class="my-3"
              contain
              height="200"
            ></v-img>
            <span class="display-2">
              Bem vindo ao Web Check-In
            </span>
            <v-row
              class="mt-3"
              justify="center"
            >
              <v-col
                cols="11"
                md="7"
              >
                <span class="title">
                  Por favor acesse novamente através do site de um dos nossos clientes ou por meio do endereço recebido no dispositivo móvel.
                </span>
              </v-col>
            </v-row>
          </v-flex>
        </v-form>
      </div>
  </v-container>
</template>

<script>
export default {
  mounted: function () {
    this.$loading(false)
  }
}
</script>
