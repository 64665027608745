/* =========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */

import axiosAPI from '../../../axios-API'

export default {
  SET_BEARER (state, accessToken) {
    axiosAPI.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    axiosAPI.defaults.headers.common['x-access-token'] = `${accessToken}`
    state.token = accessToken
  },
  SET_IS_LOGGED (state, payload) {
    state.isLogged = payload
  }
}
