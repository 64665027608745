<template>
  <v-container>
      <div class="text-center">
        <!-- <span><qrcode :value="`https://busca.clinic.inf.br/completed?code=${patient}`" :options="{ width: 200 }"></qrcode></span><br> -->
        <v-form @keyup.enter.native="search()" action="#" ref="form" @submit.prevent="search()">
          <v-flex>
            <v-img
              :src="getCustomerLogo"
              class="my-3"
              contain
              height="200"
            >
              <template v-slot:placeholder>
                <v-card
                  color="rgba(0, 0, 0, 0.1)"
                  width="100%"
                  height="100%"
                >
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
                    {{getCustomerName}}
                  </v-row>
                </v-card>
              </template>
            </v-img>
            <span class="display-2">
              Bem vindo ao Web Check-In
            </span>
            <br>
            <span class="subtitle">
              Insira abaixo seu telefone cadastrado para buscar por consultas agendadas.
            </span>
            <v-row
              justify="center"
              class="mt-3"
            >
              <v-col
                cols="9"
                sm="5"
              >
                <v-text-field
                  label="Telefone"
                  class="text-h5"
                  v-model="phoneNumber"
                  v-mask="[masks.phone, masks.cell]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-flex>

          <v-btn class="mr-4" @click="clear()">Limpar</v-btn>
          <v-btn @click="search()">Buscar</v-btn>
        </v-form>
      </div>
  </v-container>
</template>

<script>
import { mask } from 'vue-the-mask'
import maskSet from './formComponents/data/masks'
import { mapGetters, mapActions } from 'vuex'

export default {
  directives: {
    mask
  },
  mounted: function () {
    this.$loading(false)
  },
  methods: {
    ...mapActions('patient', ['findPatientSchedulesByMobile']),
    search () {
      if (!this.phoneNumber.match(/[0-9]/g)) {
        this.$alert('O campo Telefone precisa ser preenchido.', 'warning')
        return
      }
      const clearPhoneNumber = this.phoneNumber.match(/[0-9]/g).join('')
      if (clearPhoneNumber.length < 10) {
        this.$alert('Telefone inválido.', 'error')
        return
      }
      this.findPatientSchedulesByMobile(clearPhoneNumber)
        .then((obj) => {
          this.$alert(obj.text, obj.color)
        })
    },
    clear () {
      this.phoneNumber = ''
    }
  },
  data: () => ({
    masks: maskSet,
    phoneNumber: ''
  }),
  computed: {
    ...mapGetters('patient', ['getCustomerLogo', 'getCustomerName'])
  }
}
</script>
