<template>
  <v-container>
    <v-card>
      <p class="ma-3 pt-4 text-h5">Dados Pessoais</p>
      <v-form ref="formRef">
        <v-container class="pa-3 pt-0">
          <v-row>
            <!-- Paciente -->
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                :value="getPatientForm.name"
                @input="value => parametrize(value).then(filteredValue => setFormData({name: filteredValue}))"
                :rules="[rules.required]"
                :counter="40"
                label="Nome do Paciente"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <!-- Nascimento -->
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                :value="getPatientForm.birthDay"
                @input="value => parametrize(value).then(filteredValue => setFormData({birthDay: filteredValue}))"
                :rules="[rules.data, rules.required]"
                label="Data de Nascimento"
                v-mask="masks.date"
                type="tel"
                maxlength="10"
                required
              ></v-text-field>
            </v-col>

            <!-- Sexo -->
            <v-col
              cols="6"
              md="3"
            >
              <v-select
                :value="getPatientForm.gender"
                @input="value => parametrize(value).then(filteredValue => setFormData({gender: filteredValue}))"
                :items="selectOptions.sexo"
                :rules="[rules.required]"
                item-text="text"
                item-value="value"
                label="Sexo"
                required
              ></v-select>
            </v-col>

            <!-- Raça -->
            <!-- <v-col
              cols="6"
              md="3"
            >
              <v-select
                :value="getPatientForm.humanRaceCode"
                @input="value => parametrize(value).then(filteredValue => setFormData({humanRaceCode: filteredValue}))"
                :items="selectOptions.raca"
                :rules="[rules.required]"
                item-text="text"
                item-value="value"
                label="Raça"
                required
              ></v-select>
            </v-col> -->

            <!-- Estado Civil -->
            <!-- <v-col
              cols="12"
              md="3"
            >
              <v-select
                :value="getPatientForm.maritalStatus"
                @input="value => parametrize(value).then(filteredValue => setFormData({maritalStatus: filteredValue}))"
                :items="selectOptions.estadosCivis"
                :rules="[rules.required]"
                item-text="text"
                item-value="value"
                label="Estado Civil"
                required
              ></v-select>
            </v-col> -->

            <!-- Procedencia -->
            <!-- <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.origin"
                @input="value => parametrize(value).then(filteredValue => setFormData({origin: filteredValue}))"
                label="Procedência"
                maxlength="40"
              ></v-text-field>
            </v-col> -->

            <!-- Naturalidade -->
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.naturalness"
                label="Naturalidade"
                maxlength="40"
              ></v-text-field>
            </v-col>

            <!-- Nacionalidade -->
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.nationality"
                label="Nacionalidade"
                maxlength="40"
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="d-flex flex-wrap justify-space-between">
            <div class=""></div>

            <!-- proximo -->
            <v-btn
              class="mr-4"
              color="primary"
              @click="submit()"
            >
              Próximo
            </v-btn>
          </div>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import maskSet from './data/masks'
import ruleSet from './data/ruleSet'
import dataTables from './data/tables'
import { mask } from 'vue-the-mask'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'formInfoPessoal',
  directives: {
    mask
  },
  data: () => ({
    rules: ruleSet,
    masks: maskSet,
    selectOptions: {
      sexo: dataTables.sexo,
      raca: dataTables.raca,
      estadosCivis: dataTables.estadosCivis
    }
  }),
  computed: {
    ...mapGetters('patient', ['getPatientForm'])
  },
  methods: {
    ...mapActions('patient', ['setFormData', 'parametrize']),
    submit () {
      if (this.$refs.formRef.validate()) {
        this.$emit('next-form-section')
      } else {
        this.$alert('Existem campos incompletos.')
      }
    }
  }
}
</script>
