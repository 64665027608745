<template>
  <v-card>
    <v-stepper
      v-model="formSection"
    >
      <v-card-title
        class="display-1"
      >
        Check-In
      </v-card-title>
      <v-card-text>
        Preencha o formulário com os dados do paciente.
      </v-card-text>
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <infoPessoal v-on:previous-form-section="previousSection()" v-on:next-form-section="nextSection()"/>
        </v-stepper-content>

        <v-stepper-content step="2" class="pa-0">
          <endereco v-on:previous-form-section="previousSection()" v-on:next-form-section="nextSection()"/>
        </v-stepper-content>

        <v-stepper-content step="3" class="pa-0">
          <documentos v-on:previous-form-section="previousSection()" v-on:next-form-section="nextSection()"/>
        </v-stepper-content>

        <v-stepper-content step="4" class="pa-0">
          <contato v-on:previous-form-section="previousSection()" v-on:next-form-section="nextSection()"/>
        </v-stepper-content>

        <v-stepper-content step="5" class="pa-0">
          <confirmationResume v-on:previous-form-section="previousSection()" v-on:next-form-section="nextSection()"/>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import infoPessoal from './formComponents/infoPessoal'
import endereco from './formComponents/endereco'
import documentos from './formComponents/documentos'
import contato from './formComponents/contato'
import confirmationResume from './formComponents/confirmationResume'

export default {
  name: 'PatientForm',
  components: {
    infoPessoal,
    endereco,
    documentos,
    contato,
    confirmationResume
  },
  data: () => ({
    formSection: 1
  }),
  mounted () {
    this.$loading(false)
  },
  methods: {
    nextSection () {
      this.formSection++
    },
    previousSection () {
      this.formSection--
    }
  }
}
</script>
