/* =========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */
import jwt from 'jsonwebtoken'
import axiosAPI from '../../../axios-API'
import moment from 'moment'
import store from '../../store'

export default {

  // ////////////////////////////////////////////////////////////////////////////////////////
  // PAGE START SETUP
  setToken ({ commit }, payload) {
    return commit('SET_TOKEN', payload)
  },
  setCustomer ({ commit }, payload) {
    return commit('SET_CUSTOMER', payload)
  },
  setCustomerName ({ commit }, payload) {
    return commit('SET_CUSTOMER_NAME', payload)
  },
  setCustomerId ({ commit }, payload) {
    return commit('SET_CUSTOMER_ID', payload)
  },
  setCustomerCode ({ commit }, payload) {
    return commit('SET_CUSTOMER_CODE', payload)
  },
  setCustomerLogo ({ commit }, payload) {
    return commit('SET_CUSTOMER_LOGO', payload)
  },
  setLoading ({ commit }, payload) {
    return commit('SET_LOADING', payload)
  },

  // ////////////////////////////////////////////////////////////////////////////////////////
  // TOKEN HANDLE AND VALIDATION
  async setDecode ({ state, commit }, payload) {
    const data = await jwt.verify(state.token, payload)

    commit('SET_CUSTOMER_ID', data.customer)
    commit('SET_DECODED', data)
    commit('SET_TOKEN_STATUS', true)
    return data
  },

  // ////////////////////////////////////////////////////////////////////////////////////////
  // PATIENT FORM SETUP
  async getHealthInsurance ({ state, commit }) {
    if (localStorage.getItem('healthInsurance') && localStorage.getItem('healthInsurance') !== 'undefined') {
      return {
        text: 'Lista de convênios carregada com sucesso.',
        color: 'success'
      }
    }
    if (!state.customer.code || state.customer.code === '') {
      return {
        text: 'Lista de convênios carregada com sucesso.',
        color: 'success'
      }
    }
    var config = {
      headers: {
        authorization: `Bearer ${store.state.auth.token}`,
        'x-access-token': store.state.auth.token
      }
    }
    return axiosAPI.get(`/web-check-in/health-insurance?code=${state.customer.code}`, config)
      .then((response) => {
        if (response.status !== 200) { return {} }
        if (response.data.message === 'erro') {
          return {
            text: response.data.description.error,
            color: 'error'
          }
        }
        // Problema geralmente aqui V  ('result' x 'results')
        if (response.data.results.length) {
          localStorage.setItem('healthInsurance', JSON.stringify(response.data.results))
          return {
            text: 'Lista de convênios carregada com sucesso.',
            color: 'success'
          }
        }
      })
      .catch((error) => {
        console.error(error)
        return {
          text: error.message
        }
      })
  },

  findPatient ({ state, commit }, payload) {
    commit('SET_LOADING', true)
    var config = {
      headers: {
        authorization: `Bearer ${store.state.auth.token}`,
        'x-access-token': store.state.auth.token
      }
    }
    return axiosAPI.get(`/web-check-in/patient/find-one?code=${state.customer.code}&patient=${payload}`, config)
      .then((response) => {
        const data = response.data.results[0]
        const patient = {
          idSchedule: state.checkInSchedule.id || '',
          record: state.checkInSchedule.record || '',
          hour: state.checkInSchedule.hour || '',
          date: state.checkInSchedule.date || '',
          doctorCode: state.checkInSchedule.doctorCode || '',
          doctorTitle: state.checkInSchedule.doctorTitle || '',
          path: state.checkInSchedule.path || '',
          tbPathImgDoctor: state.checkInSchedule.tbPathImgDoctor || '',
          typeSCheduleCode: state.checkInSchedule.typeSCheduleCode || '',
          customer: state.customer._id || '',
          code: state.customer.code || '',
          name: state.checkInSchedule.name || data.name || '',
          birthDay: state.checkInSchedule.birthDay || data.birthDay || '',
          gender: data.gender || '',
          humanRaceCode: `${data.humanRaceCode}` || '',
          maritalStatus: `${data.maritalStatus}` || '',
          origin: data.origin || '',
          naturalness: data.naturalness || '',
          nationality: data.nationality || '',
          rg: data.rg || '',
          cpf: state.checkInSchedule.cpf.toString() || data.cpf.toString() || data.tbCPF.toString() || '',
          cns: data.cns || '',
          zipCode: data.zipCode || '',
          address: state.checkInSchedule.address || data.address || '',
          number: data.number || '',
          complement: data.complement || '',
          neighborhood: data.neighborhood || '',
          city: state.checkInSchedule.city || data.city || '',
          state: data.state || '',
          healthInsuranceCode: state.checkInSchedule.healthInsuranceCode || data.healthInsuranceCode || '',
          registration: data.registration || '',
          healthInsurancePlan: data.healthInsurancePlan || '',
          cardValidity: moment(data.cardValidity.replace('T00:00:00.000Z', '')).format('DD/MM/YYYY') || '',
          homePhone: data.homePhone || '',
          officePhone: data.officePhone || '',
          mobile: state.checkInSchedule.mobile || data.mobile || payload || '',
          email: state.checkInSchedule.email || data.email || ''
        }

        function TestaCPF (strCPF) {
          let Soma = 0
          if (strCPF === '00000000000') return false

          for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
          let Resto = (Soma * 10) % 11

          if ((Resto === 10) || (Resto === 11)) Resto = 0
          if (Resto !== parseInt(strCPF.substring(9, 10))) return false

          Soma = 0
          for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
          Resto = (Soma * 10) % 11
          if ((Resto === 10) || (Resto === 11)) Resto = 0
          if (Resto !== parseInt(strCPF.substring(10, 11))) return false
          return true
        }
        // Correção de bug cpf sem zeros a esquerda
        if (patient.cpf.length <= 11 && patient.cpf.length > 2) {
          let cpfPlusZero = patient.cpf
          while (cpfPlusZero.length < 11) {
            cpfPlusZero = '0' + cpfPlusZero
          }
          if (TestaCPF(cpfPlusZero)) {
            patient.cpf = cpfPlusZero
          }
        }

        commit('ASSIGN_FORM_DATA', patient)
        return {
          text: 'Dados do paciente carregados com sucesso',
          color: 'success'
        }
      })
      .catch((error) => {
        console.error(error)
        return {}
      })
  },

  // ////////////////////////////////////////////////////////////////////////////////////////
  // PATIENT SCHEDULES
  findPatientSchedulesByMobile ({ state, commit }, payload) {
    commit('SET_LOADING', true)
    var config = {
      headers: {
        authorization: `Bearer ${store.state.auth.token}`,
        'x-access-token': store.state.auth.token
      }
    }

    return axiosAPI.get(`/web-check-in/schedule/find-mobile?from=${state.customer.code}&mobile=${payload}`, config)
      .then((response) => {
        if (response.status !== 200) {
          const message = ''
          throw message
        }
        if (response.data.message === 'erro') {
          throw response.data.description.error
        }
        const data = response.data.results
        if (!data.length) {
          commit('SET_LOADING', false)
          const message = 'Nenhum agendamento encontrado.'
          throw message
        }
        commit('SET_PATIENT_SCHEDULES', data)
        commit('SET_LOADING', false)
        return {
          text: 'Agendamento encontrado com sucesso!',
          color: 'success'
        }
      })
      .catch((error) => {
        console.error(error)
        commit('SET_LOADING', false)
        return {
          text: error,
          color: 'error'
        }
      })
  },
  setCheckInScheduleData ({ commit }, value) {
    commit('SET_CHECKIN_SCHEDULE_DATA', value)
  },

  // ////////////////////////////////////////////////////////////////////////////////////////
  // PATIENT FORM SUBMIT
  postPatient ({ state }) {
    const payload = JSON.parse(JSON.stringify(state.patient))

    function parameterizeDate (value) {
      const dataClear = value.match(/[0-9]+/g)
      if (!dataClear) { return true }
      const date = new Date(dataClear[2] * 1, dataClear[1] * 1 - 1, dataClear[0] * 1, 0, 0, 0)
      return moment(date).format('YYYY-MM-DD[T00:00:00.000Z]')
    }

    payload.birthDay = parameterizeDate(payload.birthDay)
    payload.cardValidity = parameterizeDate(payload.cardValidity)
    payload.cpf = payload.cpf.toString().trim().replace(/[()\\\-_. ]/g, '')
    payload.homePhone = payload.homePhone.toString().trim().replace(/[()\\\-_. ]/g, '')
    payload.mobile = payload.mobile.toString().trim().replace(/[()\\\-_. ]/g, '')
    payload.officePhone = payload.officePhone.toString().trim().replace(/[()\\\-_. ]/g, '')
    payload.zipCode = payload.zipCode.toString().trim().replace(/[()\\\-_. ]/g, '')

    const config = {
      headers: {
        authorization: `Bearer ${store.state.auth.token}`,
        'x-access-token': store.state.auth.token
      }
    }

    return axiosAPI.put(`/web-check-in/schedule/appointments?code=${state.customer.code}`, payload, config)
      .then((response) => {
        if (response.data.message === 'error' || response.data.message === 'erro') {
          throw response.data
        }
        return ({
          text: 'Dados enviados com sucesso.',
          color: 'success'
        })
      })
      .catch((error) => {
        console.error(error)
        return ({
          text: false,
          color: false
        })
      })
  },

  // ////////////////////////////////////////////////////////////////////////////////////////
  // PATIENT FORM CONTROLS
  // eslint-disable-next-line no-empty-pattern
  parametrize ({}, str) {
    if (!str) return ''
    str = str.toUpperCase()
    str = str.replace(/[ÄÃÂÁÀ]/g, 'A')
    str = str.replace(/[ËẼÊÉÈ]/g, 'E')
    str = str.replace(/[ÏĨÎÍÌ]/g, 'I')
    str = str.replace(/[ÖÕÔÓÒ]/g, 'O')
    str = str.replace(/[ÜŨÛÚÙ]/g, 'U')
    str = str.replace(/[ŸỸÛÚÙ]/g, 'Y')
    return str
  },
  setFormData ({ commit }, data) {
    commit('ASSIGN_FORM_DATA', data)
  },
  clearFormData ({ commit }) {
    commit('CLEAR_FORM_DATA', {})
    commit('SET_FIRST_FORM_SECTION', 1)
  },

  // ////////////////////////////////////////////////////////////////////////////////////////
  // ALERTS
  alert ({ commit }, options) {
    const config = {
      snackbar: options.snackbar || true,
      color: options.color || 'error',
      mode: options.mode || '',
      timeout: options.timeout || 3000,
      text: options.text || 'Erro de comunicação com o servidor, por favor verifique sua conexão e tente novamente.'
    }
    commit('SET_ALERT', config)
  }
}
