/* =========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */

import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import moduleAuth from './modules/auth/moduleAuth.js'
import moduleSchedule from './modules/schedule/moduleSchedule.js'
import modulePatient from './modules/patient/modulePatient.js'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    schedule: moduleSchedule,
    patient: modulePatient
  },
  strict: process.env.NODE_ENV !== 'production'
})
