/* =========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {}

export default state
