import Vue from 'vue'
import App from './App.vue'
// plugins
import vuetify from './plugins/vuetify'
import EventBus from './plugins/event-bus'

// routers
import router from './route'

// routers
import store from './store/store'

// axios
import axios from './axios-API.js'

import VueQrcode from '@chenfengyuan/vue-qrcode'
import '@mdi/font/css/materialdesignicons.css'

Vue.prototype.$http = axios
Vue.component(VueQrcode.name, VueQrcode)

// ALERT
Vue.prototype.$alert = function (text, color, timeout, mode, snackbar) {
  const config = {
    snackbar: snackbar,
    color: color,
    mode: mode,
    timeout: timeout,
    text: text
  }
  this.$store.dispatch('patient/alert', config)
}
// LOADING
Vue.prototype.$loading = function (value) {
  this.$store.dispatch('patient/setLoading', value)
}

Vue.use(EventBus)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
