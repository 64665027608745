// axios
import axios from 'axios'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post.authorization = process.env.VUE_APP_AUTHORIZATION

export default axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Authorization: process.env.VUE_APP_AUTHORIZATION,
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'x-access-token': process.env.VUE_APP_X_ACCESS_TOKEN
  }
})
