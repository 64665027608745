<template>
  <v-container>
    <v-card>
      <p class="ma-3 pt-4 text-h5">Endereço</p>
      <v-form ref="formRef">
        <v-container class="pa-3 pt-0">
          <v-row>
            <!-- CEP -->
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-on:keyup.enter="verifyCEP(getPatientForm.zipCode)"
                :value="getPatientForm.zipCode"
                @input="value => setFormData({zipCode: value})"
                :rules="[rules.cep]"
                label="CEP"
                type="tel"
                maxlength="9"
                v-mask="masks.cep"
                append-icon="mdi-map-search"
                @click:append="() => verifyCEP(getPatientForm.zipCode)"
              ></v-text-field>
            </v-col>

            <!-- Logadouro -->
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                :value="getPatientForm.address"
                @input="value => setFormData({address: value})"
                :rules="[rules.required]"
                label="Logradouro"
                maxlength="40"
                required
              ></v-text-field>
            </v-col>

            <!-- Numero -->
            <!-- <v-col
              cols="4"
              md="2"
            >
              <v-text-field
                :value="getPatientForm.number"
                @input="value => setFormData({number: value})"
                label="Numero"
                maxlength="10"
              ></v-text-field>
            </v-col> -->

            <!-- neighborhood -->
            <v-col
              cols="6"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.neighborhood"
                @input="value => setFormData({neighborhood: value})"
                :rules="[rules.required]"
                :readonly="!!(foundCep && getPatientForm.neighborhood)"
                :disabled="!!(foundCep && getPatientForm.neighborhood)"
                label="Bairro"
                maxlength="40"
                required
              ></v-text-field>
            </v-col>

            <!-- city -->
            <v-col
              cols="6"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.city"
                @input="value => setFormData({city: value})"
                :rules="[rules.required]"
                :readonly="!!(foundCep && getPatientForm.city)"
                :disabled="!!(foundCep && getPatientForm.city)"
                label="Cidade"
                maxlength="40"
                required
              ></v-text-field>
            </v-col>

            <!-- Estado -->
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                :value="getPatientForm.state"
                @input="value => setFormData({state: value})"
                :items="selectOptions.estados"
                :rules="[rules.required]"
                :readonly="!!(foundCep && getPatientForm.state)"
                :disabled="!!(foundCep && getPatientForm.state)"
                item-text="nome"
                item-value="uf"
                label="Estado"
                required
              ></v-select>
            </v-col>

            <!-- complement -->
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                :value="getPatientForm.complement"
                @input="value => setFormData({complement: value})"
                label="Complemento"
                maxlength="40"
                :counter="40"
                required
              ></v-text-field>
            </v-col>

          </v-row>
          <div class="d-flex flex-wrap justify-space-between">
            <!-- voltar -->
            <v-btn
              class="mr-4"
              @click="$emit('previous-form-section')"
            >
              Voltar
            </v-btn>

            <div class=""></div>

            <!-- proximo -->
            <v-btn
              class="mr-4"
              color="primary"
              @click="submit()"
            >
              Próximo
            </v-btn>
          </div>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
import maskSet from './data/masks'
import ruleSet from './data/ruleSet'
import dataTables from './data/tables'
import { mask } from 'vue-the-mask'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'formEndereco',
  directives: {
    mask
  },
  props: [
    'validDecode'
  ],
  computed: {
    ...mapGetters('patient', ['getPatientForm'])
  },
  data: () => ({
    snackbarConfig: {
      snackbar: false,
      color: 'error',
      mode: '',
      timeout: 5000,
      text: 'Erro de comunicação com o servidor'
    },
    rules: ruleSet,
    masks: maskSet,
    selectOptions: {
      estados: dataTables.estados
    },
    foundCep: false
  }),
  methods: {
    ...mapActions('patient', ['setFormData']),
    submit () {
      if (this.$refs.formRef.validate()) {
        this.$emit('next-form-section')
      } else {
        this.$alert('Existem campos incompletos.')
      }
    },
    verifyCEP (zipCode) {
      if (!zipCode) { return }
      const zipCodeClear = zipCode.match(/([0-9]+)/g).join('')
      if (!zipCodeClear || zipCodeClear.length !== 8) {
        return
      }
      const getAddress = axios.create({
        baseURL: 'https://viacep.com.br/ws/'
      })
      getAddress.get(`${zipCodeClear}/json/`)
        .then((response) => {
          if (response.data.erro) {
            this.foundCep = false
            this.$alert('CEP não encontrado.')
            return
          }
          const address = response.data
          this.foundCep = true
          this.setFormData({
            address: address.logradouro,
            neighborhood: address.bairro,
            city: address.localidade,
            state: address.uf
          })
        })
        .catch((err) => {
          this.$alert('CEP não encontrado.')
          console.error(err)
        })
    }
  }
}
</script>
