<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col cols="12" sm="9" md="7" lg="5" he>
      <v-card class="pa-6">
        <v-img
              :src="getCustomerLogo"
              class="my-3"
              contain
              height="200"
            >
              <template v-slot:placeholder>
                <v-card
                  color="rgba(0, 0, 0, 0.1)"
                  width="100%"
                  height="100%"
                >
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
                    {{getCustomerName}}
                  </v-row>
                </v-card>
              </template>
            </v-img>
        <v-card-text
          class="display-1 text-center w-100"
        >
          Confirmar agendamento
        </v-card-text>
        <v-card-text class="w-100 text-center">
          Para confirmar o agendamento, clique no botão abaixo.
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            x-large
            @click="confirmMethod"
          >
            Confirmar
          </v-btn>
          <v-spacer />
          <v-btn
            color="error"
            @click="cancelDialog"
            x-large
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
// eslint-disable-next-line no-undef
const Swal = SweetAlert

export default {
  name: 'Patient',
  data: () => ({
    id: null
  }),
  mounted () {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    } else {
      this.$router.push({ name: 'Home' })
    }

    this.$loading(false)
  },
  methods: {
    ...mapActions('schedule', ['confirm', 'cancel']),
    async confirmMethod () {
      try {
        const result = await this.confirm(this.id)

        if (result) {
          Swal.fire('Agendamento confirmado!', 'O agendamento foi confirmado com sucesso.', 'success')
        } else {
          Swal.fire('Erro ao confirmar agendamento!', 'Não foi possível confirmar o agendamento.', 'error')
        }
      } catch (error) {
        Swal.fire('Algo deu errado ao confirmar seu agendamento!', 'Não foi possível confirmar o seu agendamento. Por favor entre em contato com a clínica para efetuar a confirmação.', 'error')
      }
    },
    cancelDialog () {
      console.log(this)
      Swal.fire({
        title: '',
        html: '<b>Tem certeza de que deseja cancelar o agendamento?</b>',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const reqResult = await this.cancel(this.id)
          if (reqResult) {
            Swal.fire('Agendamento cancelado!', 'Para reagendar entre em contato com o hospital ou clínica.', 'success')
          } else {
            Swal.fire('Erro ao cancelar agendamento!', 'Não foi possível cancelar o agendamento. Por favor entre em contato com a clínica para efetuar o cancelamento.', 'error')
          }
        }
      }).catch((error) => {
        Swal.fire('Erro ao cancelar agendamento!', 'Não foi possível cancelar o agendamento. Por favor entre em contato com a clínica para efetuar o cancelamento.' + error, 'error')
      })
    }
  },
  computed: {
    ...mapGetters('patient', ['getCustomerLogo', 'getCustomerName'])
  }
}
</script>
