<template>
  <v-app>
    <v-navigation-drawer
      v-model="primaryDrawer.model"
      clipped
      floating
      temporary
      app
      overflow
    >
      <div class="">
        <v-switch
          v-model="$vuetify.theme.dark"
          primary
          style="z-index: 9999;"
          class="float-right mr-4"
          label="Dark"
        ></v-switch>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Serviços
            </v-list-item-title>
            <v-list-item-subtitle>
              Online Check-In
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list
          dense
          nav
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            @click="() => $router.push(item.to)"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <v-footer
        absolute
        class="text-center"
      >
        <v-img
          src="./assets/logo.png"
          class="my-3"
          contain
          height="30"
          style="bottom: 0"
        ></v-img>
        <div
          style="width: 100%"
        >
          <a href="https://www.clinic.com.br" target="_blank">www.clinic.med.br</a><br>
          <a href="mailyo:suporte@clinic.med.br" target="_blank">suporte@clinic.med.br</a><br>
          <a href="https://www.clinic.com.br/lgpd-pol%C3%ADtica-de-privacidade" target="_blank">Política de Privacidade</a>
        </div>
      </v-footer>
    </v-navigation-drawer>

    <v-app-bar
      app
    >
      <v-app-bar-nav-icon
        @click.stop="primaryDrawer.model = !primaryDrawer.model"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>Web Check-In</v-toolbar-title>

    </v-app-bar>

    <v-main>
      <!-- LOADER -->
      <div class="loader d-flex align-center justify-center flex-column" v-if="loading">
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        <span style="color: #fff">Carregando ...</span>
      </div>

      <!-- SNACKBAR - ALERT -->
      <v-snackbar
        :value="alert.snackbar"
        @input="value => SET_ALERT_FALSE(value)"
        :color="alert.color"
        :multi-line="alert.mode === 'multi-line'"
        :vertical="alert.mode === 'vertical'"
        :timeout="alert.timeout"
        bottom
        right
      >
        {{ alert.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            dark
            text
            v-bind="attrs"
            @click="alert.snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <!-- ROUTER -->
      <router-view></router-view>
    </v-main>
      <v-banner
        v-if="consented"
        dark
        color="rgba(0, 81, 201, 0.95)"
        style="position: absolute; bottom: 35px;"
      >
        <v-row
          justify="space-around"
          align="center"
          class="mx-3"
        >
          <span v-html="consentMessage"></span>
          <v-btn
            @click="lgpd()"
            light
            class="mt-4"
          >
            Aceitar
          </v-btn>
        </v-row>
      </v-banner>
    <v-footer>
      <span class="px-4">&copy; {{ new Date().getFullYear() }} Clinic Sistemas em Saúde</span>
    </v-footer>
  </v-app>

</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'App',
  created () {
    // this.$$http.
    // this.getToken()
    this.consented = !localStorage.getItem('cookieConsent')
  },
  data: () => ({
    primaryDrawer: {
      model: null
    },
    items: [
      { title: 'Inicio', icon: 'mdi-home', to: '/' }
      // { title: 'Perguntas e Respostas', icon: 'mdi-information-variant', to: '/' },
      // { title: 'Fale Conosco', icon: 'mdi-phone', to: '/' }
    ],
    consented: false,
    consentMessage: `
      <h3>Termo de uso</h3><br>
      Utilizamos tecnologias de armazenamento local neste site para melhorar sua funcionalidade e desempenho, 
      operacionalizar os nossos sistemas, habilitar recursos e para analisar o tráfego de nossas páginas. 
      Coletamos e armazenamos temporariamente alguns dados pessoais.<br><br>

      Nenhuma informação pessoal de usuário que navegou ou preencheu algum formulário deste site será divulgada publicamente 
      e nos comprometemos a não vender, alugar, ceder por qualquer forma ou repassar a terceiros suas informações pessoais.<br><br>
      
      Ao navegar em nosso site, você concorda com a nossa Política de Armazenamento Local e com a nossa 
      <a style="color: #eeff69" href="https://www.clinic.com.br/lgpd-pol%C3%ADtica-de-privacidade" target="_blank">Política de Privacidade</a>.`
  }),
  computed: {
    ...mapGetters('patient', ['loading', 'alert'])
  },
  methods: {
    ...mapMutations('patient', ['SET_ALERT_FALSE']),
    // async getToken () {
    //   const formBody = {
    //     username: process.env.VUE_APP_API_USER,
    //     password: process.env.VUE_APP_API_PASSWORD
    //   }
    //   try {
    //     const responseAuth = await this.$http.post(process.env.VUE_APP_API_URL_LOGIN, formBody)
    //     this.$http.interceptors.request.use(
    //       config => {
    //         const token = responseAuth.data.result.token
    //         if (token) {
    //           config.headers['x-access-token'] = responseAuth.data.result.token
    //         }
    //         // config.headers['Content-Type'] = 'application/json'
    //         return config
    //       },
    //       error => {
    //         Promise.reject(error)
    //       })
    //   } catch (err) {
    //     window.console.error(err)
    //   }
    // },
    lgpd () {
      localStorage.setItem('cookieConsent', this.consentMessage)
      this.consented = !localStorage.getItem('cookieConsent')
    }
  }
}
</script>

<style scoped>
.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: linear 1s;
  background-color: rgba(1, 1, 1, 0.8);
  z-index: 1;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
