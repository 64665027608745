/* =========================================================================================
File Name: moduleAuthMutations.js
Description: Auth Module Mutations
----------------------------------------------------------------------------------------
Item Name: Web-Check-In
Author: Spitzer
Author URL: https://www.spitzer.com.br
========================================================================================== */

export default {
  // //////////////////////////////////////
  // SESSION CONTROLS
  SET_CREATE (state, payload) {
    state.isLogged = payload
  },
  SET_TOKEN (state, payload) {
    state.token = payload
  },
  SET_CUSTOMER (state, payload) {
    Object.assign(state.customer, payload)
    localStorage.setItem('customer', JSON.stringify(state.customer))
  },
  SET_CUSTOMER_ID (state, payload) {
    state.customer._id = payload
    localStorage.setItem('customer', JSON.stringify(state.customer))
  },
  SET_CUSTOMER_CODE (state, payload) {
    state.customer.code = payload
    localStorage.setItem('customer', JSON.stringify(state.customer))
  },
  SET_CUSTOMER_LOGO (state, payload) {
    state.customer.logo = payload
    localStorage.setItem('customer', JSON.stringify(state.customer))
  },
  SET_CUSTOMER_NAME (state, payload) {
    state.customer.name = payload
    localStorage.setItem('customer', JSON.stringify(state.customer))
  },

  // //////////////////////////////////////
  // PATIENT FORM CONTROLS
  CLEAR_FORM_DATA (state) {
    state.patient = {}
  },
  SET_FORM_STATE (state, value) {
    state.formOpen = value
  },

  // //////////////////////////////////////
  // PATIENT FORM DATA
  ASSIGN_FORM_DATA (state, formData) {
    Object.assign(state.patient, formData)
  },
  SET_PATIENT_DATA_LOADED (state, payload) {
    state.patientDataLoaded = payload
  },

  // //////////////////////////////////////
  // CHECK-IN SCHEDULE
  SET_CHECKIN_SCHEDULE_DATA (state, payload) {
    state.checkInSchedule = payload
  },
  SET_PATIENT_SCHEDULES (state, payload) {
    state.patientSchedules = payload
  },

  // //////////////////////////////////////
  // TOKEN CONTROLS
  SET_DECODED (state, payload) {
    state.decoded = payload
  },
  SET_TOKEN_STATUS (state, payload) {
    state.tokenValid = payload
  },

  // //////////////////////////////////////
  // APP CONTROLS DATA
  SET_LOADING (state, value) {
    state.loading = value
  },

  // //////////////////////////////////////
  // ALERT
  SET_ALERT (state, value) {
    Object.assign(state.alert, value)
  },
  SET_ALERT_FALSE (state, value) {
    state.alert.snackbar = value
  },

  SET_HEALT_INSURANCE (state, payload) {
    state.healtInsurance = payload
  }
}
