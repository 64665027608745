/* =========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */

// import spitzer from '../../http/requests/auth/spitzer/index.js'
// import router from '@/router'
import axiosAPI from '../../../axios-API'

export default {
  // Spitzer
  loginSpitzer ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const formBody = {
        username: process.env.VUE_APP_API_USER,
        password: process.env.VUE_APP_API_PASSWORD
      }
      axiosAPI.post(process.env.VUE_APP_API_URL_LOGIN, formBody).then((responseAuth) => {
        const token = responseAuth.data.result.token
        if (token) {
          commit('auth/SET_BEARER', token, { root: true })
          commit('auth/SET_IS_LOGGED', true, { root: true })
          resolve()
        }
      }).catch((err) => {
        window.console.error(err)
        reject(err)
      })
    })
  }
}
