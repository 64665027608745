<template>
  <v-container>
    <v-card>
      <p class="ma-3 pt-4 text-h5">Documentos</p>
      <v-form ref="formRef">
        <v-container class="pa-3 pt-0">
          <v-row>

            <!-- CPF -->
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.cpf"
                @input="value => setFormData({cpf: value})"
                :rules="[rules.required, rules.cpf]"
                label="CPF"
                type="tel"
                maxlength="14"
                v-mask="masks.cpf"
                required
              ></v-text-field>
            </v-col>

            <!-- RG -->
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.rg"
                @input="value => setFormData({rg: value})"
                label="RG"
                maxlength="15"
              ></v-text-field>
            </v-col>

            <!-- CNS -->
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.cns"
                @input="value => setFormData({cns: value})"
                label="CNS"
                maxlength="40"
              ></v-text-field>
            </v-col>

            <!-- Convenio -->
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                :value="getPatientForm.healthInsuranceCode"
                @input="value => setFormData({healthInsuranceCode: value})"
                :items="healthInsurance"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
                label="Convênio"
                required
              ></v-select>
            </v-col>

            <!-- Matricula -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                :value="getPatientForm.registration"
                @input="value => setFormData({registration: value})"
                :rules="[rules.required]"
                label="Matrícula"
                maxlength="40"
                required
              ></v-text-field>
            </v-col>

            <!-- Plano -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                :value="getPatientForm.healthInsurancePlan"
                @input="value => setFormData({healthInsurancePlan: value})"
                :rules="[rules.required]"
                label="Plano"
                maxlength="40"
                required
              ></v-text-field>
            </v-col>

            <!-- Validade da carteira -->
            <!-- <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                :value="getPatientForm.cardValidity"
                @input="value => setFormData({cardValidity: value})"
                :rules="[rules.required, rules.data, rules.futureDate]"
                label="Validade da Carteira"
                type="tel"
                maxlength="10"
                v-mask="masks.date"
                required
              ></v-text-field>
            </v-col> -->

          </v-row>
          <div class="d-flex flex-wrap justify-space-between">
            <!-- voltar -->
            <v-btn
              class="mr-4"
              @click="$emit('previous-form-section')"
            >
              Voltar
            </v-btn>

            <div class=""></div>

            <!-- proximo -->
            <v-btn
              class="mr-4"
              color="primary"
              @click="submit()"
            >
              Próximo
            </v-btn>
          </div>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import maskSet from './data/masks'
import ruleSet from './data/ruleSet'
import dataTables from './data/tables'
import { mask } from 'vue-the-mask'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'formDocumentos',
  directives: {
    mask
  },
  props: [
    'validDecode'
  ],
  data: () => ({
    rules: ruleSet,
    masks: maskSet,
    selectOptions: {
      convenios: dataTables.convenios
    }
  }),
  computed: {
    ...mapGetters('patient', ['getPatientForm']),
    healthInsurance: function () {
      return JSON.parse(localStorage.getItem('healthInsurance'))
    }
  },
  methods: {
    ...mapActions('patient', ['setFormData']),
    submit () {
      if (this.$refs.formRef.validate()) {
        this.$emit('next-form-section')
      } else {
        this.$alert('Existem campos incompletos.')
      }
    }
  }
}
</script>
