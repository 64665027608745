<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col cols="11" md="10">

      <tokenValidator v-if="!patientSchedules.length && !isTokenValid && getToken" />

      <findPatient v-if="!patientSchedules.length && getCustomerCode && !getToken" />

      <patientSchedules v-if="patientSchedules.length" />

    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

import findPatient from '../components/Patient/FindPatient'
import tokenValidator from '../components/Patient/TokenValidator'
import patientSchedules from '../components/Patient/PatientSchedules'

export default {
  name: 'Patient',
  components: {
    findPatient,
    tokenValidator,
    patientSchedules
  },
  computed: {
    ...mapGetters('patient', ['isTokenValid', 'getToken', 'patientSchedules', 'getCustomerCode', 'isFormOpen'])
  }
}
</script>
