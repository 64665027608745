<template>
  <v-container>
    <v-card>
      <p class="ma-3 pt-4 text-h5">Contato</p>
      <v-form ref="formRef">
        <v-container class="pa-3 pt-0">
          <v-row>

            <!-- Fone Residencial -->
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.homePhone"
                @input="value => setFormData({homePhone: value})"
                :rules="[rules.onlyNumbers]"
                type="tel"
                label="Telefone Residencial"
                v-mask="[masks.phone, masks.cell]"
                maxlength="16"
              ></v-text-field>
            </v-col>

            <!-- Fone Comercial -->
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.officePhone"
                @input="value => setFormData({officePhone: value})"
                :rules="[rules.onlyNumbers]"
                type="tel"
                label="Telefone Comercial"
                v-mask="[masks.phone, masks.cell]"
                maxlength="16"
              ></v-text-field>
            </v-col>

            <!-- Celular -->
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                :value="getPatientForm.mobile"
                @input="value => setFormData({mobile: value})"
                :rules="[rules.onlyNumbers]"
                label="Celular"
                v-mask="[masks.phone, masks.cell]"
                type="tel"
                maxlength="16"
                required
              ></v-text-field>
            </v-col>

            <!-- Email -->
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                :value="getPatientForm.email"
                @input="value => setFormData({email: value})"
                :rules="[rules.required, rules.email]"
                label="Email"
                maxlength="40"
                required
              ></v-text-field>
            </v-col>

          </v-row>
          <div class="d-flex flex-wrap justify-space-between">
            <!-- voltar -->
            <v-btn
              class="mr-4"
              @click="$emit('previous-form-section')"
            >
              Voltar
            </v-btn>

            <div class=""></div>

            <!-- proximo -->
            <v-btn
              class="mr-4"
              color="primary"
              @click="submit()"
            >
              Conferir Agendamento
            </v-btn>
          </div>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import maskSet from './data/masks'
import ruleSet from './data/ruleSet'
import { mask } from 'vue-the-mask'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'formContato',
  directives: {
    mask
  },
  props: [
    'validDecode'
  ],
  data: () => ({
    rules: ruleSet,
    masks: maskSet
  }),
  computed: {
    ...mapGetters('patient', ['getPatientForm'])
  },
  methods: {
    ...mapActions('patient', ['setFormData']),
    submit () {
      if (this.$refs.formRef.validate()) {
        this.$emit('next-form-section')
      } else {
        this.$alert('Existem campos incompletos.')
      }
    }
  }
}
</script>
