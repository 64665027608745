/* =========================================================================================
  File Name: moduleAuth.js
  Description: Auth Module
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */

import state from './modulePatientState.js'
import mutations from './modulePatientMutations.js'
import actions from './modulePatientActions.js'
import getters from './modulePatientGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
