<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col cols="11" md="10">

      <patientForm />

    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import patientForm from '../components/Patient/PatientForm'

export default {
  name: 'Patient',
  components: {
    patientForm
  },
  mounted: function () {
    if (!this.checkInSchedule.mobile) {
      return this.$router.push('/')
    }
    // this.findPatient(this.checkInSchedule.mobile.match(/[0-9]/g).join(''))
    if (this.checkInSchedule.record > 0) {
      this.findPatient(this.checkInSchedule.record)
        .then((alert) => {
          this.$loading(false)
          this.$alert(alert.text, alert.color)
        })
        .catch((err) => {
          console.error(err)
          this.$alert('Falha de conexão, não foi possível buscar os dados do paciente.')
        })
    } else {
      this.setSearchPatientSchedulebyId(this.checkInSchedule.id)
        .then((alert) => {
          this.$loading(false)
          this.$alert(alert.text, alert.color)
        })
        .catch((err) => {
          console.error(err)
          this.$alert('Falha de conexão, não foi possível buscar os dados do paciente.')
        })
    }
  },
  computed: {
    ...mapGetters('patient', ['checkInSchedule'])
  },
  methods: {
    ...mapActions('patient', ['findPatient', 'findPatientSchedulesByMobile']),
    ...mapActions('schedule', ['setSearchPatientSchedulebyId'])
  }
}
</script>
