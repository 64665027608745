<template>
  <div class="text-center">
    <!-- <span><qrcode :value="`https://busca.clinic.inf.br/completed?code=${patient}`" :options="{ width: 200 }"></qrcode></span><br> -->
    <v-flex>
      <v-img
        :src="getCustomerLogo"
        class="mb-3"
        contain
        height="200"
      >
        <template v-slot:placeholder>
          <v-card
            color="rgba(0, 0, 0, 0.1)"
            width="100%"
            height="100%"
          >
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular indeterminate color="grey lighten-1"></v-progress-circular>
              {{getCustomerName}}
            </v-row>
          </v-card>
        </template>
      </v-img>
      <span class="display-2">
        Bem vindo ao Web Check-In
      </span>
      <br>
      <span class="title">Token identificado</span>
      <br>
      <span class="subtitle">Digite seu telefone cadastrado para validar o token de acesso.</span>
      <v-row
        justify="center"
        class="mt-0"
      >
        <v-col
          cols="12"
          sm="5"
        >
          <v-text-field
            label="Digite seu telefone (99) 9 9999-9999"
            class="text-h5"
            type="tel"
            v-model="tokenKey"
            v-mask="[masks.phone, masks.cell]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn class="mr-3" @click="clear()">Limpar</v-btn>
      <v-btn class="" @click="decode()">Validar</v-btn>
    </v-flex>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import maskSet from './formComponents/data/masks'

export default {
  name: 'tokenDecode',
  directives: {
    mask
  },
  mounted () {
    this.$loading(false)
  },
  data: () => ({
    tokenKey: '',
    masks: maskSet
  }),
  computed: {
    ...mapGetters('patient', ['getCustomerLogo', 'getCustomerName'])
  },
  methods: {
    ...mapActions('patient', ['setDecode', 'findPatientSchedulesByMobile', 'setTokenStatus']),
    decode () {
      if (!this.tokenKey) { return }
      if (!this.tokenKey.match(/[0-9]+/g)) { return }
      const decodeKey = this.tokenKey.match(/[0-9]+/g).join('')
      this.setDecode(decodeKey).then((item) => {
        if (item.name !== 'JsonWebTokenError') {
          this.findPatientSchedulesByMobile(decodeKey)
            .then(() => {
              this.$alert('Agendamentos encontrados com sucesso!', 'success', 1000)
            })
            .catch(() => {
              this.$alert()
            })
          this.$alert('Token validado com sucesso!', 'success', 1000)
        } else {
          this.$alert('Falha ao validar token')
        }
      }).catch((err) => {
        console.error(err)
        this.$alert('Falha ao validar token')
      })
    },
    clear () {
      this.tokenKey = ''
    }
  }
}
</script>
