/* =========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Web-Check-In
  Author: Spitzer
  Author URL: https://www.spitzer.com.br
========================================================================================== */

export default {
  // //////////////////////////////////////
  // App data
  loading: state => state.loading,

  // //////////////////////////////////////
  // Session info
  getCustomer: state => state.customer,
  getCustomerCode: state => state.customer.code,
  getCustomerId: state => state.customer._id,
  getCustomerName: state => state.customer.name,
  getCustomerLogo: state => state.customer.logo,

  // //////////////////////////////////////
  // Token data
  getToken: state => state.token,
  isTokenValid: state => state.tokenValid,

  // //////////////////////////////////////
  // Patient form controls
  isFormOpen: state => state.formOpen,

  // //////////////////////////////////////
  // Patient form data
  getPatientForm: state => state.patient,
  checkInPayload: state => state.checkInPayload,

  // //////////////////////////////////////
  // Patient schedules
  patientSchedules: state => state.patientSchedules,
  checkInSchedule: state => state.checkInSchedule,

  // //////////////////////////////////////
  // ALERT
  alert: state => state.alert,

  // //////////////////////////////////////
  // HEALTHINSURANCE
  getHealhtInsurance: state => state.healhtInsurance

}
