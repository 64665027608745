<template>
  <v-row justify="center">
    <v-col
      cols="11"
      md="8"
    >
      <v-card class="pa-3" elevation="24">
      <span class="title">Confirme as informações de check-in</span>
        <span class="text-subtitle-1 font-weight-bold float-right">ID: {{getPatientForm.idSchedule}}</span>
        <v-row>
          <v-col>
            <span class="text-subtitle-1 font-weight-bold">Paciente</span><br>
            <span class="pl-3 text-subtitle-2">{{getPatientForm.name}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-subtitle-1 font-weight-bold">Data</span><br>
            <span class="pl-3 text-subtitle-2">{{getDate}}</span>
          </v-col>
          <v-col>
            <span class="text-subtitle-1 font-weight-bold">Hora</span><br>
            <span class="pl-3 text-subtitle-2">{{getTime}}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <span class="text-subtitle-1 font-weight-bold">Médico</span><br>
            <br>
            <v-avatar>
              <!-- <v-img
                :src="getPatientForm.tbPathImgDoctor"
              / -->
              <v-icon
                color="blue"
              >
                {{ getPatientForm.doctorTitle[0] }}
              </v-icon>
            </v-avatar>
            <span class="pl-3 text-subtitle-2">{{getPatientForm.doctorTitle}}</span>
          </v-col>
        </v-row>
        <div class="d-flex flex-wrap justify-space-between">
          <!-- voltar -->
          <v-btn
            class="mr-4"
            @click="$emit('previous-form-section')"
          >
            Voltar
          </v-btn>

          <div class=""></div>

          <!-- enviar -->
          <v-btn
            class="mr-4"
            color="primary"
            @click="send()"
          >
            Confirmar Check-IN
          </v-btn>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  data: () => ({
    payload: {}
  }),
  mounted () {
    this.$loading(false)
  },
  computed: {
    ...mapGetters('patient', ['getPatientForm']),
    getDate: function () {
      return moment(this.getPatientForm.date, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    getTime: function () {
      return moment(this.getPatientForm.hour, 'YYYY-MM-DD HH:mm').format('HH:mm')
    }
  },
  methods: {
    ...mapActions('patient', ['postPatient']),
    send () {
      this.postPatient()
        .then((res) => {
          this.$alert(res.text, res.color)
          if (res.color === 'success') { this.$router.push('/success') }
        })
    }
  }
}
</script>
